define("discourse/plugins/discourse-blizzard-plugin/discourse/widgets/character-details", ["discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_renderGlimmer, _widget, _templateFactory) {
  "use strict";

  (0, _widget.createWidget)("character-details", {
    html(attrs) {
      let contents = [new _renderGlimmer.default(this, "div.character-details-wrap", (0, _templateFactory.createTemplateFactory)(
      /*
        <CharacterDetails @dataUserCard={{true}} @showPostCount={{true}} @showName={{true}} @user={{@data.user}} @character={{@data.character}}/>
      */
      {
        "id": "vnPphFKE",
        "block": "[[[8,[39,0],null,[[\"@dataUserCard\",\"@showPostCount\",\"@showName\",\"@user\",\"@character\"],[true,true,true,[30,1,[\"user\"]],[30,1,[\"character\"]]]],null]],[\"@data\"],false,[\"character-details\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/widgets/character-details.js",
        "isStrictMode": false
      }), {
        character: attrs.character,
        //Not really a user - this is a post, but it has enough user elements to play well with character-details component
        user: attrs
      })];
      const title = attrs.user_title,
        titleIsGroup = attrs.title_is_group,
        primaryGroupName = attrs.primary_group_name;
      if (title && title.length) {
        contents.push(this.attach("poster-name-title", {
          title,
          primaryGroupName,
          titleIsGroup
        }));
      }
      return contents;
    }
  });
});