define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/user-profile-avatar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BUserProfileAvatar @user={{@user}} />
  */
  {
    "id": "xS5D7t9E",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,1]]],null]],[\"@user\"],false,[\"b-user-profile-avatar\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/user-profile-avatar.hbs",
    "isStrictMode": false
  });
});