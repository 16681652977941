define("discourse/plugins/discourse-blizzard-plugin/discourse/widgets/b-post-statuses", ["discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_renderGlimmer, _widget, _templateFactory) {
  "use strict";

  (0, _widget.createWidget)("b-post-statuses", {
    html() {
      return [new _renderGlimmer.default(this, "div.b-post-status-wrapper", (0, _templateFactory.createTemplateFactory)(
      /*
        <BPostStatuses @postMetadata={{@data.postMetadata}}/>
      */
      {
        "id": "UP73Byyd",
        "block": "[[[8,[39,0],null,[[\"@postMetadata\"],[[30,1,[\"postMetadata\"]]]],null]],[\"@data\"],false,[\"b-post-statuses\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/widgets/b-post-statuses.js",
        "isStrictMode": false
      }), {
        postMetadata: this.attrs
      })];
    }
  });
});