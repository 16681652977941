define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/before-group-container/group-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      let m = location.toString().match(/category_id=(\d+)/);
      let category;
      if (m) {
        let categoryId = parseInt(m[1], 10);
        if (categoryId) {
          category = component.get("site.categories").findBy("id", categoryId);
        }
      }
      component.set("category", category);
    }
  };
});