define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/before-topic-list-body/pinned-topics-tbody", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.discoveryList}}
    <BPinnedTopicsBody
      @topics={{this.topics}}
      @bulkSelectEnabled={{this.bulkSelectEnabled}}
      @lastVisitedTopic={{this.lastVisitedTopic}}
      @selected={{this.selected}}
      @hideCategory={{this.hideCategory}}
    />
  {{/if}}
  */
  {
    "id": "5tnty0GX",
    "block": "[[[41,[30,0,[\"discoveryList\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@topics\",\"@bulkSelectEnabled\",\"@lastVisitedTopic\",\"@selected\",\"@hideCategory\"],[[30,0,[\"topics\"]],[30,0,[\"bulkSelectEnabled\"]],[30,0,[\"lastVisitedTopic\"]],[30,0,[\"selected\"]],[30,0,[\"hideCategory\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"b-pinned-topics-body\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/before-topic-list-body/pinned-topics-tbody.hbs",
    "isStrictMode": false
  });
});