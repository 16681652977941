define("discourse/plugins/discourse-blizzard-plugin/initializers/remove-routes", ["exports", "rsvp", "discourse/lib/plugin-api", "discourse-common/lib/get-url"], function (_exports, _rsvp, _pluginApi, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function redirectRoute(destination) {
    return {
      beforeModel() {
        return new _rsvp.Promise(() => document.location = destination);
      }
    };
  }
  const showNotFound = redirectRoute((0, _getUrl.default)("/404"));
  const TO_REMOVE = ["about", "faq", "tos", "privacy", "forgot-password", "user-private-messages"];
  var _default = _exports.default = {
    name: "remove-routes",
    after: "inject-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        // Redirect certain routes to 404
        TO_REMOVE.forEach(r => api.modifyClass(`route:${r}`, {
          pluginId: "blizzard",
          ...showNotFound
        }));
        api.modifyClass(`route:guidelines`, {
          pluginId: "blizzard",
          ...redirectRoute("https://battle.net/support/article/000256989")
        });
      });
    }
  };
});