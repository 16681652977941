define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/character-class", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = characterClass;
  function characterClass(character) {
    if (character && character.player_class_enum) {
      return `character-class ${character.player_class_enum}`;
    } else if (character && character.class) {
      return `character-class ${(0, _string.dasherize)(character.class)}`;
    }
  }
});