define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-profile-controls/franchise-profile-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      showProfile() {
        let profileUrl = this.get("model")?.custom_fields?.profile_url;
        window.open(profileUrl, "_blank");
      }
    }
  };
});