define("discourse/plugins/discourse-blizzard-plugin/discourse/widgets/b-nav-breadcrumbs", ["exports", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_exports, _renderGlimmer, _widget, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("b-nav-breadcrumbs", {
    buildKey: () => "b-nav-breadcrumbs",
    html() {
      return [new _renderGlimmer.default(this, "div.b-breadcrumbs-wrap", (0, _templateFactory.createTemplateFactory)(
      /*
        <BNavBreadcrumbs @topic={{@data.topic}}/>
      */
      {
        "id": "kLhL26wO",
        "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[\"b-nav-breadcrumbs\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/widgets/b-nav-breadcrumbs.js",
        "isStrictMode": false
      }), {
        topic: this.attrs.topic
      })];
    }
  });
});