define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/preferences/emails", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BAccountLink @user="model" />
  */
  {
    "id": "CXNspkLT",
    "block": "[[[8,[39,0],null,[[\"@user\"],[\"model\"]],null]],[],false,[\"b-account-link\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/preferences/emails.hbs",
    "isStrictMode": false
  });
});