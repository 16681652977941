define("discourse/plugins/discourse-blizzard-plugin/initializers/populate-footer-cookie-link", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "disable-ember-routing-navbar-footer-cookie-link",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        // Each page load resets the footer; disable ember routing on the Navbar cookies link to force a page reload
        // so the cookie script can run
        api.onPageChange(() => {
          let element = document.querySelector('.NavbarFooter-anchor[data-id$="cookies"]');
          if (element) {
            element.setAttribute("data-auto-route", "true");
          }
        });
      });
    }
  };
});