define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-after-username/character-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CharacterDetails
    @character={{this.user.character}}
    @showName="true"
    @user={{this.user}}
    @showUser={{this.showUser}}
  />
  */
  {
    "id": "n29n0SiR",
    "block": "[[[8,[39,0],null,[[\"@character\",\"@showName\",\"@user\",\"@showUser\"],[[30,0,[\"user\",\"character\"]],\"true\",[30,0,[\"user\"]],[30,0,[\"showUser\"]]]],null]],[],false,[\"character-details\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-after-username/character-details.hbs",
    "isStrictMode": false
  });
});