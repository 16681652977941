define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/character-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = characterDescription;
  function characterDescription(character) {
    if (character) {
      return `${character.level || ""} ${character.race || ""} ${character.class || ""}`.trim();
    }
  }
});