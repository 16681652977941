define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/notes/buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#styleguide-markdown}}
    #### Button Notes This is an example note about buttons.
  {{/styleguide-markdown}}
  */
  {
    "id": "M0wVh0Gz",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  #### Button Notes This is an example note about buttons.\\n\"]],[]]]]]],[],false,[\"styleguide-markdown\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/notes/buttons.hbs",
    "isStrictMode": false
  });
});