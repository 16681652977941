define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/after-panel-body/select-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(_, component) {
      component.set("canChangeAvatar", this.siteSettings.selectable_avatars_mode !== "disabled" || this.currentUser?.get("staff"));
      component.set("canChangeCharacter", this.siteSettings.selectable_characters_enabled);
      component.set("displayButtons", this.canChangeAvatar || this.canChangeCharacter);
    },
    actions: {
      showAvatarSelector(user) {
        this.appEvents.trigger("dom:clean", "header");
        this.appEvents.trigger("show-avatar-select", user);
        return false;
      },
      showCharacterSelector(user) {
        this.appEvents.trigger("dom:clean", "header");
        this.appEvents.trigger("show-character-select", user);
        return false;
      }
    }
  };
});