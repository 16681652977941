define("discourse/plugins/discourse-blizzard-plugin/lib/discourse-markdown/plaintext-battle-net", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setupMarkdownIt(helper) {
    helper.registerOptions(opts => {
      opts.features["plaintext-battle-net"] = true;
    });
    helper.registerPlugin(md => {
      md.validateLink = function (url) {
        let BAD_PROTO_RE = /^(vbscript|javascript|file|data):/;
        let GOOD_DATA_RE = /^data:image\/(gif|png|jpeg|webp);/;
        let BATTLE_NET_RE = /^http:\/\/battle\.net$/; // `battle.net` becomes normalized this way, any other battle.net path will not match this
        // url should be normalized at this point, and existing entities are decoded
        let str = url.trim().toLowerCase();
        if (BATTLE_NET_RE.test(str)) {
          return false;
        }
        return BAD_PROTO_RE.test(str) ? GOOD_DATA_RE.test(str) ? true : false : true;
      };
    });
  }
  function setup(helper) {
    if (helper.markdownIt) {
      setupMarkdownIt(helper);
    }
  }
});