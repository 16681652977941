define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/group-post-additional-member-info/post-count", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if user.post_count}}
    <div class="post-count">
      {{i18n "user_posts_count" count=user.post_count}}
    </div>
  {{/if}}
  */
  {
    "id": "i6FRCoZL",
    "block": "[[[41,[30,0,[\"user\",\"post_count\"]],[[[1,\"  \"],[10,0],[14,0,\"post-count\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"user_posts_count\"],[[\"count\"],[[30,0,[\"user\",\"post_count\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/group-post-additional-member-info/post-count.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `user` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/group-post-additional-member-info/post-count.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/group-post-additional-member-info/post-count.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});