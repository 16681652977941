define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/cookies", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    {{html-safe (i18n "cookie_consent.details.body.html")}}
  </div>
  
  <div id="optanon-cookie-policy"></div>
  */
  {
    "id": "QKixGg0z",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[1,[28,[35,0],[[28,[37,1],[\"cookie_consent.details.body.html\"],null]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,1,\"optanon-cookie-policy\"],[12],[13]],[],false,[\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/cookies.hbs",
    "isStrictMode": false
  });
});