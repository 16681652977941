define("discourse/plugins/discourse-blizzard-plugin/initializers/populate-navbar", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api", "discourse-common/lib/get-url"], function (_exports, _loadScript, _pluginApi, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "populate-navbar",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", async api => {
        const nav = document.querySelector("blz-nav, blz-nav-battlenet");
        const routeApplication = api.container.lookup("route:application");
        const siteSettings = api.container.lookup("service:site-settings");
        const site = api.container.lookup("service:site");
        const user = api.getCurrentUser();
        if (nav) {
          nav.addEventListener("blz-nav:cms-loaded", _ref => {
            let {
              detail
            } = _ref;
            populateURLs(siteSettings, detail);
          });
        }
        let navbarScript = "https://navbar.blizzard.com/static/v1/nav.js";
        if (site.get("blizzard_franchise") === "blizzard") {
          navbarScript = "https://navbar.blizzard.com/static/v1/nav-battlenet.js";
        }
        await (0, _loadScript.default)(navbarScript);
        await (0, _loadScript.default)("https://navbar.blizzard.com/static/v1/footer.js");
        if (nav) {
          //  Add a listener to both shadow and light DOMs to prevent regressions
          nav?.addEventListener("pointerup", e => handleClick(e, routeApplication));
          nav?.shadowRoot?.addEventListener("pointerup", e => handleClick(e, routeApplication));

          /**
           * User specific data will be loaded into the blizzard navbar on page load. This
           * will help with caching the navbar on authenticated requests.
           */
          if (user) {
            nav.user = {
              battletag: user.battletag,
              flags: {
                employee: user.blizzard_employee
              }
            };
          }
        }
      });
    }
  };
  function handleClick(e, routeApplication) {
    if (e.target.href?.includes("logout")) {
      routeApplication.send("logout");
      e.preventDefault();
    }
  }
  function populateURLs(siteSettings, detail) {
    detail.cmsContent.filter(item => item?.href?.startsWith("/") || item?.href?.startsWith("#")).forEach(element => {
      const address = element.href;
      if (address.includes("login")) {
        element.setAttribute("href", (0, _getUrl.default)("/auth/bnet"));
      } else if (address.includes("logout")) {
        element.setAttribute("href", "#logout");
      } else if (!address.includes("auth/bnet")) {
        element.setAttribute("href", `${siteSettings.blizzard_nav_links_base_url}${address}`);
      }
    });
  }
});