define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-post-names/character", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CharacterDetails @character={{this.model.character}} />
  */
  {
    "id": "Axn5Rtc6",
    "block": "[[[8,[39,0],null,[[\"@character\"],[[30,0,[\"model\",\"character\"]]]],null]],[],false,[\"character-details\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-post-names/character.hbs",
    "isStrictMode": false
  });
});