define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/realm-name", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.helper(_ref => {
    let [category] = _ref;
    if (category) {
      return category.get("category_metadata.long_name") || category.get("name");
    }
  });
});