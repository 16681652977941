define("discourse/plugins/discourse-blizzard-plugin/initializers/setup-gtm-vars", ["exports", "discourse/lib/plugin-api", "discourse-common/config/environment", "discourse-common/lib/object"], function (_exports, _pluginApi, _environment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "setup-gtm-vars",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.17", api => {
        // We don't need GA in test mode
        if ((0, _environment.isTesting)()) {
          return;
        }
        let site = api.container.lookup("service:site");
        let user = api.getCurrentUser();
        let gtmBlizzData = {
          authenticated: user ? 1 : 0,
          userId: user ? user.bnet_id : 0,
          gaClientID: site.gtm_data.gaClientID,
          locale: site.gtm_data.locale,
          localeRegion: site.gtm_data.localeRegion,
          serverRegion: site.gtm_data.serverRegion,
          project: site.gtm_data.project
        };
        api.addGTMPageChangedCallback(gtmData => (0, _object.deepMerge)(gtmData, gtmBlizzData));
      });
    }
  };
});