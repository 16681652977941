define("discourse/plugins/discourse-blizzard-plugin/discourse/widgets/user-post-count", ["discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_renderGlimmer, _widget, _templateFactory) {
  "use strict";

  (0, _widget.createWidget)("user-post-count", {
    tagName: "div.user-count-wrap",
    html(attrs) {
      return [new _renderGlimmer.default(this, "span.user-count-wrap", (0, _templateFactory.createTemplateFactory)(
      /*
        <UserPostCount @userPostCount={{@data.userPostCount}}/>
      */
      {
        "id": "+uG0eH+t",
        "block": "[[[8,[39,0],null,[[\"@userPostCount\"],[[30,1,[\"userPostCount\"]]]],null]],[\"@data\"],false,[\"user-post-count\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/widgets/user-post-count.js",
        "isStrictMode": false
      }), {
        userPostCount: attrs.user_post_count
      })];
    }
  });
});