define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/atoms/03-icons-blizzard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#styleguide-example title="d-icon - blizzard"}}
    {{d-icon "blizzard-info"}}
    {{d-icon "blizzard-circle-plus"}}
    {{d-icon "blizzard-open-mail"}}
    {{d-icon "blizzard-shortcut"}}
    {{d-icon "blizzard-grid-3"}}
    {{d-icon "blizzard-video"}}
    {{d-icon "blizzard-zoom-in"}}
    {{d-icon "blizzard-zoom-out"}}
    {{d-icon "blizzard-ow"}}
    {{d-icon "blizzard-wow"}}
  {{/styleguide-example}}
  
  <a
    href="https://pages.ghosthub.corp.blizzard.net/web-and-mobile/ui-blizzard/guide/"
  >
    {{i18n "styleguide.sections.icons_blizzard.full_list"}}
  </a>
  */
  {
    "id": "kvwd6eM3",
    "block": "[[[6,[39,0],null,[[\"title\"],[\"d-icon - blizzard\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],[\"blizzard-info\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-circle-plus\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-open-mail\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-shortcut\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-grid-3\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-video\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-zoom-in\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-zoom-out\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-ow\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"blizzard-wow\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,3],[14,6,\"https://pages.ghosthub.corp.blizzard.net/web-and-mobile/ui-blizzard/guide/\"],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"styleguide.sections.icons_blizzard.full_list\"],null]],[1,\"\\n\"],[13]],[],false,[\"styleguide-example\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/atoms/03-icons-blizzard.hbs",
    "isStrictMode": false
  });
});