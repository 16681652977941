define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/below-group-activity-nav/members-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#link-to route="group.activity.members"}}
    {{d-icon "users"}}
    {{i18n "groups.members"}}
  {{/link-to}}
  */
  {
    "id": "XrNrKKIL",
    "block": "[[[6,[39,0],null,[[\"route\"],[\"group.activity.members\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],[\"users\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"groups.members\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/below-group-activity-nav/members-link.hbs",
    "isStrictMode": false
  });
});