define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-boxes", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each categories as |c index|}}
    <div class="subcategory-container {{third-class index}}">
      <BSubcategory @category={{c}} />
    </div>
  {{/each}}
  */
  {
    "id": "Jc13BzmN",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"subcategory-container \",[28,[37,2],[[30,2]],null]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null],[1,[28,[32,0],[\"[[\\\"The `categories` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-boxes.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.categories}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"c\",\"index\"],false,[\"each\",\"-track-array\",\"third-class\",\"b-subcategory\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-boxes.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});