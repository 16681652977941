define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.categories}}
    <div class="category-list">
      {{component categoryListComponent categories=this.categories}}
    </div>
  {{/if}}
  
  {{#if siteSettings.blizzard_realms_enabled}}
    {{b-realms-list categories=this.categories filter="yours"}}
    {{b-realms-list categories=this.categories filter="classic"}}
    {{b-realms-list categories=this.categories filter="all"}}
  {{/if}}
  */
  {
    "id": "pZintu7q",
    "block": "[[[41,[30,0,[\"categories\"]],[[[1,\"  \"],[10,0],[14,0,\"category-list\"],[12],[1,\"\\n    \"],[46,[30,0,[\"categoryListComponent\"]],null,[[\"categories\"],[[30,0,[\"categories\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_realms_enabled\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"yours\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"classic\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"all\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `categoryListComponent` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.categoryListComponent}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"component\",\"b-realms-list\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});