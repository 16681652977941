define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-preferences-account/blizzard-custom-instructions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(_ref, component) {
      let {
        model
      } = _ref;
      component.set("canChangeAvatar", this.currentUser && this.currentUser.get("staff") && model === this.currentUser || this.siteSettings.selectable_avatars_mode !== "disabled" && this.currentUser && (this.currentUser.get("staff") || model === this.currentUser));
      component.set("canChangeCharacter", !this.get("canChangeAvatar") && this.siteSettings.selectable_characters_enabled && this.currentUser === model);
    },
    actions: {
      showAvatarSelector(user) {
        this.appEvents.trigger("dom:clean", "header");
        this.appEvents.trigger("show-avatar-select", user);
        return false;
      },
      showCharacterSelector(user) {
        this.appEvents.trigger("dom:clean", "header");
        this.appEvents.trigger("show-character-select", user);
        return false;
      }
    }
  };
});