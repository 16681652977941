define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/notes/topic-notifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#styleguide-markdown}}
    NOTE: This component will not have the proper padding/margins unless it is
    inside a div with ID `topic-footer-buttons`.
  {{/styleguide-markdown}}
  */
  {
    "id": "Nk5bwHu3",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  NOTE: This component will not have the proper padding/margins unless it is\\n  inside a div with ID `topic-footer-buttons`.\\n\"]],[]]]]]],[],false,[\"styleguide-markdown\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/notes/topic-notifications.hbs",
    "isStrictMode": false
  });
});