define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-profile-controls/franchise-profile-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.custom_fields.profile_url}}
    {{d-button
      class="btn-primary user-card-button"
      action="showProfile"
      icon="user"
      label="user.view_profile"
    }}
  {{/if}}
  */
  {
    "id": "/uS6Guzx",
    "block": "[[[41,[30,0,[\"model\",\"custom_fields\",\"profile_url\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\",\"action\",\"icon\",\"label\"],[\"btn-primary user-card-button\",\"showProfile\",\"user\",\"user.view_profile\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-profile-controls/franchise-profile-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-profile-controls/franchise-profile-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});