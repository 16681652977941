define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-pinned-topics-body", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.pinnedTopics}}
    <tbody class="pinned-topics">
      {{#each this.pinnedTopics as |topic|}}
        {{topic-list-item
          topic=topic
          bulkSelectEnabled=@bulkSelectEnabled
          lastVisitedTopic=@lastVisitedTopic
          selected=@selected
          hideCategory=@hideCategory
        }}
      {{/each}}
      <tr class="table-break">
        <td colspan="10">&nbsp;</td>
      </tr>
    </tbody>
  {{/if}}
  */
  {
    "id": "Mz5LgPhO",
    "block": "[[[41,[30,0,[\"pinnedTopics\"]],[[[1,\"  \"],[10,\"tbody\"],[14,0,\"pinned-topics\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"pinnedTopics\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"topic\",\"bulkSelectEnabled\",\"lastVisitedTopic\",\"selected\",\"hideCategory\"],[[30,1],[30,2],[30,3],[30,4],[30,5]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[10,\"tr\"],[14,0,\"table-break\"],[12],[1,\"\\n      \"],[10,\"td\"],[14,\"colspan\",\"10\"],[12],[1,\" \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"topic\",\"@bulkSelectEnabled\",\"@lastVisitedTopic\",\"@selected\",\"@hideCategory\"],false,[\"if\",\"each\",\"-track-array\",\"topic-list-item\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-pinned-topics-body.hbs",
    "isStrictMode": false
  });
  class BPinnerdTopicsBody extends _component2.default {
    get pinnedTopics() {
      return this.args.topics.filter(t => t.get("pinned"));
    }
  }
  _exports.default = BPinnerdTopicsBody;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BPinnerdTopicsBody);
});