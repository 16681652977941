define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/to-lower-case", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.helper(_ref => {
    let [str] = _ref;
    return str.toLowerCase();
  });
});